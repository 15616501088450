<template>
<div>
  <van-cell-group title="通用">
    <van-cell title="新的记录" is-link size="large" icon="records" to="/utl/rent/new" />
    <van-cell title="单据查询" is-link size="large" icon="search" to="/utl/rent/lst" />
  </van-cell-group>
</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped></style>